import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import get from "lodash/get";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import styles from "./index.module.css";
import NewsletterSignup from "../components/newsletterSignup";
import CallToActionGeneric from "../components/callToActionGeneric";

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const siteDescription = get(
      this,
      "props.data.site.siteMetadata.description"
    );
    const signatureStyles = {
      marginTop: "-20px",
    };
    const jeffTitleStyles = {
      marginTop: "0px",
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          meta={[{ name: "description", content: siteDescription }]}
          title={siteTitle}
        />
        <div>
          <Img
            title="Lewis Mohr Insurance Agency"
            alt="Lewis Mohr Insurance Office in Baton Rouge Louisiana"
            fluid={this.props.data.mainBg.childImageSharp.fluid}
            className={styles.mainBg}
            imgStyle={{ objectPosition: "center right" }}
          />
          <div className={styles.mainContent}>
            <h1>Locally Trusted Insurance</h1>
            <h2 className={styles.mainDescription}>
              Since 1968, we've been serving Baton Rouge and all of Louisiana's
              personal and business insurance needs. Contact us today for a
              quote.
            </h2>
            <div className={styles.homeButtonGroup}>
              <Link
                className="button button-large button-secondary"
                to={"/get-insurance/"}
              >
                Get an Insurance Quote
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-justifyCenter three-column-callout">
          <div className="grid-cell u-med-3of4">
            <div className="grid grid-gutters-xl">
              <div className="grid-cell">
                <Img
                  alt="Abstact lighting"
                  fixed={this.props.data.icon1.childImageSharp.fixed}
                  className=""
                />
                <h6>Family-Owned Since 1968</h6>
                <p>
                  We’ve been proudly serving Baton Rouge and all of Louisiana
                  for more than 50 years.
                </p>
              </div>
              <div className="grid-cell">
                <Img
                  alt="Abstact lighting"
                  fixed={this.props.data.icon2.childImageSharp.fixed}
                  className=""
                />
                <h6>Independent Agency</h6>
                <p>
                  We're an independent agency, which means more options and
                  cheaper rates for you.
                </p>
              </div>
              <div className="grid-cell">
                <Img
                  alt="Abstact lighting"
                  fixed={this.props.data.icon3.childImageSharp.fixed}
                  className=""
                />
                <h6>Trusted Expertise</h6>
                <p>
                  We stand by our principles and ethics, and offer
                  industry-leading insurance expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="short center"></hr>
        <div className="get-insurance-home u-textCenter">
          <h3>Get in touch for an insurance quote</h3>
          <h6>
            Our Baton Rouge, Louisiana based insurance agents are ready to help
            you find the right coverage.
          </h6>
          <Link className="button" to={"/get-insurance/"}>
            Get a Quote
          </Link>
        </div>
        <div className="primary-bg">
          <div className="grid grid-justifyCenter">
            <div className="grid-cell ie-fix callout">
              <h2 className={styles.calloutTitle}>Over 50 Years of Service</h2>
              <div className={styles.separator}></div>
              <p>
                It was in February of 1968, in Baton Rouge, Louisiana, that
                Lewis Mohr decided to open the agency in pursuit of the American
                dream. Although a lot has changed since the agency was started,
                the one thing that has remained constant is that we still adhere
                to the same principles, beliefs, and ethics that this agency was
                founded on. We look forward to what the future holds for our
                agency, and look forward to serving our clients with the
                continued reliability they have come to trust.
              </p>
              <Img
                title="Jeffrey Mohr Signature"
                alt="signature"
                fixed={this.props.data.jeffSignature.childImageSharp.fixed}
                style={signatureStyles}
              />
              <p style={jeffTitleStyles}>
                Jeffrey M. Mohr, CPCU, ARM
                <br />
                President
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-flex-cells">
          <div className="grid-cell u-full u-med-1of2">
            <div className="side-section">
              <h4>Why You Need An Independent Insurance Agent</h4>
              <p>
                An independent insurance agent has the power to find you the
                best insurance for your situation because they are not tied to
                just one provider. This saves you the headache of searching for
                the right insurance provider and the best chance of finding the
                best insurance coverage for you. Additionally, you'll always
                have a local agent in Baton Rouge, Louisiana to speak with for
                any of your personal and business insurance needs.
              </p>
              <Link className="button" to={"/get-insurance/"}>
                Get a Quote
              </Link>
            </div>
          </div>
          <div className="grid-cell u-full u-med-1of2">
            <div className="image-100-width">
              <Img
                title="Lewis Mohr Insurance Employees"
                alt="Two women discussing insurance"
                fluid={this.props.data.sideImage.childImageSharp.fluid}
                className="image-100-width"
              />
            </div>
          </div>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default BlogIndex;

export const fluidImage = graphql`
  fragment mainBg on File {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "lewis-mohr-office.jpg" }) {
      ...mainBg
    }
    jeffSignature: file(relativePath: { eq: "jeff-signature.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
    sideImage: file(relativePath: { eq: "homepage-side-image.jpg" }) {
      ...mainBg
    }
    icon1: file(relativePath: { eq: "why-choose-lewis-mohr-icon-1.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    icon2: file(relativePath: { eq: "why-choose-lewis-mohr-icon-3.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    icon3: file(relativePath: { eq: "why-choose-lewis-mohr-icon-2.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
